import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import "firebase/compat/app-check";

var prodConfig = {
  apiKey: "AIzaSyDwqeEX-PH5IV9pMboDUSgz0g6nWAoTTho",
  authDomain: "el-ocho.firebaseapp.com",
  databaseURL: "https://el-ocho.firebaseio.com",
  projectId: "el-ocho",
  storageBucket: "el-ocho.appspot.com",
  messagingSenderId: "770366666625",
  appId: "1:770366666625:web:baa10cb881cb53419b1a80",
  measurementId: "G-V9FZRMFQV6",
};

firebase.initializeApp(prodConfig);

firebase.auth().languageCode = "es";

export const db = firebase.firestore();
export const fb = firebase;
