<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
@import "~@/assets/scss/brands.scss";
@import "~@/assets/scss/fontawesome.scss";
@import "~@/assets/scss/light.scss";
@import "~@/assets/scss/regular.scss";
@import "~@/assets/scss/solid.scss";

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_light-webfont.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_bold-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

#app {
  background-color: #f5f5f5;
}

h1,
h3,
h3 {
  font-weight: bold;
  color: black;
}

.position-relative {
  position: relative;
}

.leaflet-top {
  width: 100% !important;
}

.leaflet-control-geosearch {
  display: none;
}

.leaflet-left {
  display: none;
}

.leaflet-control-zoom.leaflet-bar a {
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.leaflet-control-zoom {
  border: none !important;
  padding: 5px;
  right: 0;
  transition: 0.4s 0.1s all cubic-bezier(0.44, 0.1, 0.28, 1.59);
  &.leaflet-bar {
    box-shadow: none;
    border-radius: 0;
    a,
    a:hover {
      filter: drop-shadow(0px 0px 10px rgba(#ff1744, 0.8));
      font-family: "Font Awesome 5 Pro";
      font-style: normal;
      font-weight: 400;
      background-color: #ff1744;
      border-bottom: none;
      width: 28px;
      height: 28px;
      line-height: 28px;
      display: block;
      text-align: center;
      text-decoration: none;
      color: white;
      border-radius: 12px;
    }
    a:first-child {
      margin-bottom: 10px;
    }
  }
}
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
  border-radius: 50% !important;
}
.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}
</style>
