import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

import "./firebase";
import firebase from "firebase/compat/app";
import VueFirestore from "vue-firestore";

import router from "./router";

import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

Vue.use(VueFirestore);
Vue.use(firebase);

Object.defineProperty(Vue.prototype, "firebase", {
  get() {
    return this.$root.firebase;
  },
});

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
